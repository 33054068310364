// .dropdown-menu.show {
//     display: block !important;
// }


    .help-block{
      color: #cc0000;
    }


.urlImages{

  height: 200px;
  width: 100%;
  border: 1px solid black;
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 4px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1111;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
.pointer{
cursor: pointer !important;
}
.disabledInput{
  background: #c3c3c3;
}

.navbar-nav , .navbar-dark{
  
  
  li{
    a{
font-size: 10px;
    }
  } }

  .badges{
    color: #0B488B;
    font-weight: 600;
    font-size: 10px;

    .bg-usrType{
      background-color: #D9E3F2;
      border: 1px solid #D9E3F2;
      border-radius: 2px;
      padding: 0 10px;
    }
    .bg-status{
      background-color:#92CF4F;
      border: 1px solid #92CF4F;
      border-radius: 2px;
      padding: 0 10px;
    }
    .bg-day{
      background-color:#e4e9d6;
      border: 1px solid #92CF4F;
      border-radius: 2px;
      padding: 0 10px;
    }    
    .bg-expired{
      background-color:#CFCCCD;
      border: 1px solid #CFCCCD;
      border-radius: 2px;
      padding: 0 10px;
    }
    #color-1{ //outof stock
      background-color:#d4d663;
      border: 1px solid #d4d663;
    }
    #color-3{ //new
      background-color:#92CF4F;
      border: 1px solid #92CF4F;
    }
    #color-2{ //lightning
      background-color:#feb483;
      border: 1px solid #feb483;
    }
    #color-5{ //Missed
      background-color:#CFCCCD;
      border: 1px solid #CFCCCD;
    }
    #color-6{ //Active
      background-color:#b3b9f0;
      border: 1px solid #feb483;
    }   
  }

  .item_price{

    .deal_old_price{
      vertical-align: unset;
      font-weight: 400;
      font-size: 15px;
    }
  }
.featured-item{

  background-color: #ebf5ff !important;
}

// .signup_wrap .btn:disabled,.signup_wrap btn.disabled:hover{background-color:#c3c3c3;border-color:#c3c3c3}

.signup_wrap .btn{

  &:disabled{
    background-color:#c3c3c3;border-color:#c3c3c3
  }
}

.product_item{

  .badges{
    min-height:40px
  }
}

.deal_section {
  .fa-thumbs-up:hover {
   &:hover{
    color: #999;
   }
}


}
.deal_views_comments {
  .fa-thumbs-up:hover {
   &:hover{
    color: #999;
   }
}


}
.hdlSearch{

  input {
    width: 80%;
    height: 35px;
    border: 1px solid #dee2e6;
    border-radius: 3px 0 0 3px;
    border-right: 0;
    padding: 0 10px;
    &:focus{
      outline: none;
    }
  }
  button{
    height: 35px;
    border: 1px solid #dee2e6;
    border-radius: 0 3px 3px 0;
    &:focus{
      outline: none;
    }

  }
}

